html,
body {
    padding: 0;
    margin: 0;
}

.verticalCenter {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
}

.flexBottom {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-end;
}

////////////////////////////////////////////////
///
/// Quil editor
///
////////////////////////////////////////////////
.ql-editor {
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
}

.smallquill .ql-editor {
    min-height: 130px;
    max-height: 130px;
}
.largeQuill .ql-editor {
    min-height: 350px;
    max-height: 350px;
}
.quill > .ql-container > .ql-editor.ql-blank::before {
    /* font-size: 20px; */
    font-style: normal;
    color: #cecece;
}
